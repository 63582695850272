













































import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      opened: false,
      workspceName: ''
    };
  },
  computed: {
    ...mapGetters('teams', ['getTeam'])
  },
  methods: {
    async onSumbit() {
      if (this.workspceName === this.getTeam.name) {
        await this.$store.dispatch('teams/delete');
        await this.$store.dispatch('subscription/fetchMyWorkspace');
        this.closeModal();
      }
    },
    reset() {
      this.workspceName = '';
    },
    closeModal() {
      this.$modal.hide('delete-workspace');
    }
  }
});
