<template>
  <div>
    <div v-if="loading" />
    <div v-else>
      <p class="text-2xl mb-4">{{ $t('subscription.workspace_management') }}</p>

      <p class="text-lg">{{ $t('workspace') }}</p>
      <p class="text-sm	 text-gray-caption mb-4">
        {{ $t('description') }}
      </p>
      <div v-if="teams.length || search">
        <div class="flex mb-6">
          <div class="relative hidden sm:block">
            <base-input-text
              v-model="search"
              inner-class="base-input--gray"
              :placeholder="$t('search')"
              icon-fa="search"
              type="text"
              class="input-pr-8"
              style="background: #F2F3F4;"
            />
            <div class="icon--clear" @click="clearClick">
              <base-icon
                v-if="search"
                data-testid="icon-close"
                size="14"
                name="close"
              >
                <icon-close />
              </base-icon>
            </div>
          </div>
          <base-button
            icon="plus"
            icon-size="16"
            color="white"
            wide="px-3"
            class="ml-auto border-brand-blue text-brand-blue"
            shadowless
            @click="createWorkspace"
            >{{ $t('add_workspace') }}</base-button
          >
        </div>

        <div
          v-for="(team, index) in filterPerPage"
          :key="index"
          class="border px-4 pt-3 mb-4"
        >
          <div class="flex mb-6">
            <img class="object-cover w-8 h-8" :src="team.imageLogo" />
            <div class="font-medium ml-2 self-center truncate max-w-xs">
              {{ team.name }}
            </div>
            <base-button
              icon="OpenWorkspace"
              icon-size="18"
              class="text-sm"
              color="text"
              style="color: #2F80ED"
              @click="toWorkspace(team.id)"
            >
              {{ $t('workspace_table.go_to') }}
            </base-button>
            <base-dropdown
              max-width="220"
              position="bottom-right"
              class="cursor-pointer seat-dropdown__morpme ml-auto"
            >
              <base-icon slot="toggle">
                <icon-dots-horizontal />
              </base-icon>
              <base-dropdown-item
                v-for="{ label, classes, icon, modal } in confirmMenus"
                :key="label"
                :class="classes"
                @click="toggleTeam(modal, team.id)"
              >
                <base-icon :width="18"> <component :is="icon"/></base-icon>
                {{ label }}</base-dropdown-item
              >
            </base-dropdown>
          </div>
          <div class="flex text-left gap-x-10 pb-4">
            <div class="border-r pr-4 w-4/12">
              <p class="text-xs	text-gray-caption">
                {{ $t('workspace_table.owner') }}
              </p>
              <div class="text-sm flex-row	flex items-center truncate">
                <img
                  class="w-8 h-8 object-cover rounded-full mr-2"
                  :src="profile.profilePicture"
                />
                <div class="truncate">
                  <p>{{ profile.name }}</p>
                  <p class="text-gray-caption">
                    {{ profile.email }}
                  </p>
                </div>
              </div>
            </div>
            <div class="w-2/12">
              <p class="text-xs	text-gray-caption">
                {{ $t('workspace_table.description') }}
              </p>
              <p class="text-sm">
                {{
                  $i18n.locale === 'th'
                    ? team.teamType.valueTh
                    : team.teamType.valueEN
                }}
              </p>
            </div>
            <div class="border-r pr-4 w-3/12 truncate">
              <p class="text-xs	text-gray-caption">
                {{ $t('workspace_table.web') }}
              </p>
              <div class="text-sm truncate">
                <a
                  v-if="isWebsiteFormat(team.webSite)"
                  :href="webSite(team.webSite)"
                  target="_blank"
                  :class="{ 'text-blue-700 hover:underline': team.webSite }"
                >
                  {{ team.webSite }}
                </a>
                <span v-else>{{ team.webSite || '-' }}</span>
              </div>
            </div>
            <div class="border-r pr-4 w-1/12">
              <p class="text-xs	text-gray-caption">
                {{ $t('workspace_table.kanban') }}
              </p>
              <p class="text-sm whitespace-nowrap">
                {{ $tc('subscription.board', team.numberOfKanban) }}
              </p>
            </div>
            <div class="border-r pr-4 w-1/12">
              <p class="text-xs	text-gray-caption">
                {{ $t('workspace_table.agile') }}
              </p>
              <p class="text-sm whitespace-nowrap">
                {{ $tc('subscription.board', team.numberOfAgile) }}
              </p>
            </div>
            <div class="w-1/12">
              <p class="text-xs	text-gray-caption">
                {{ $t('workspace_table.waterfall') }}
              </p>
              <p class="text-sm whitespace-nowrap">
                {{ $tc('subscription.board', team.numberOfWaterfall) }}
              </p>
            </div>
          </div>
        </div>
        <base-pagination
          v-if="numOfMember > 10"
          :list="numOfMember"
          @pageCurrent="setPageCurrent"
        />
      </div>
      <div v-else class="text-center mt-40">
        <base-icon size="191"> <icon-empty-workspace /></base-icon>
        <div style="margin-botton:36px">
          <p class="text-xl mb-2 font-medium">
            {{ $t('empty_workspace.title') }}
          </p>
          <p
            class="text-sm text-gray-caption"
            v-html="$t('empty_workspace.description')"
          />
        </div>

        <div class="mt-6">
          <base-button @click="openModal('create-workspace')">
            {{ $t('create.workspace') }}
          </base-button>
        </div>
      </div>
      <modal
        ref="update-team"
        name="update-team"
        styles="background-color: transparent;"
        :width="560"
        adaptive
        height="auto"
        scrollable
      >
        <update-team-form />
      </modal>
      <ModalDeleteWorkspace />
    </div>
  </div>
</template>
<script>
import VClamp from 'vue-clamp';
import IconDotsHorizontal from '@/components/icons/IconDotsHorizontal';
import IconTrash from '@/components/icons/IconTrash';
import IconEditTeam from '@/components/icons/IconEditTeam';
import { mapGetters } from 'vuex';
import apis from '@/services/apis';
import UpdateTeamForm from '@/components/update-team-form';
import IconClose from '@/components/icons/IconClose.vue';
import ModalDeleteWorkspace from '@/components/modal/modal-delete-workspace';
import IconEmptyWorkspace from '@/components/icons/IconEmptyWorkspace';
export default {
  components: {
    VClamp,
    IconDotsHorizontal,
    IconTrash,
    UpdateTeamForm,
    IconClose,
    IconEditTeam,
    ModalDeleteWorkspace,
    IconEmptyWorkspace
  },
  data() {
    return {
      loading: true,
      userOwner: [],
      teamId: null,
      search: '',
      pageSelected: 1,
      dataPerPage: 10
    };
  },
  async created() {
    await this.$store.dispatch('subscription/fetchMyWorkspace');
    this.loading = false;
  },
  computed: {
    ...mapGetters('subscription', ['getMyWorkspace']),
    confirmMenus() {
      const editWorkspace = {
        label: this.$t('team.teamDetails.edit'),
        classes: '',
        icon: 'icon-edit-team',
        modal: 'update-team'
      };

      const removeMember = {
        label: this.$t('team.teamDetails.delete'),
        classes: 'text-red-danger',
        icon: 'icon-trash',
        modal: 'delete-workspace'
      };

      return [editWorkspace, removeMember];
    },
    teams() {
      const filterName = this.getMyWorkspace.filter(
        f => f.name.toLowerCase().search(this.search.toLowerCase()) >= 0
      );
      return this.search != '' ? filterName : this.getMyWorkspace;
    },
    filterPerPage() {
      const queryResultPerPage = this.teams.slice(
        (this.pageSelected - 1) * this.dataPerPage,
        this.pageSelected * this.dataPerPage
      );
      return queryResultPerPage;
    },
    profile() {
      return this.$store.getters['auth/getUser'];
    },
    numOfMember() {
      const numAll = this.teams.length;
      const numFiltered = this.filterPerPage.length;
      return numFiltered != numAll ? numAll : numFiltered;
    }
  },
  watch: {
    search: {
      handler(val) {
        this.pageSelected = 1;
      },
      deep: true
    }
  },
  methods: {
    isWebsiteFormat(webSite) {
      const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      const regex = new RegExp(expression);
      return webSite.match(regex) ? true : false;
    },
    webSite(webSite) {
      if (webSite.startsWith('http://') || webSite.startsWith('https://'))
        return webSite;
      else return `http://${webSite}`;
    },
    setPageCurrent(e) {
      this.pageSelected = e;
    },
    setPerPage(e) {
      this.dataPerPage = e;
    },
    createWorkspace() {
      this.$modal.show('create-workspace');
    },
    async toggleTeam(modal, teamId) {
      await this.$store.dispatch('teams/fetchTeam', teamId);
      this.$modal.show(modal);
    },
    owner(id) {
      const owner = this.userOwner.find(e => e.id == id);
      return owner;
    },
    clearClick() {
      this.search = '';
    },
    toWorkspace(id) {
      this.$router.push({
        name: 'Teams-Dashboard',
        params: { id: id }
      });
    },
    openModal(modal) {
      this.$modal.show(modal);
    }
  }
};
</script>
<i18n>
{
  "en": {
    "title": "Workspace Management",
    "workspace": "Workspace",
    "description": "Organize your team and view all the details here. Make teamwork management more efficient than ever where you can create, edit and remove everything in one place.",
    "add_workspace": "Add Workspace",
    "workspace_table":{
      "go_to": "Go to workspace",
      "owner": "Owner",
      "description": "Description",
      "web": "Website",
      "kanban": "Kanban",
      "agile": "Agile",
      "waterfall": "Waterfall"
    },
    "search":"Search Workspace",
    "empty_workspace":{
      "title":"Workspace Not Found",
      "description":"Simply create your Workspace by clicking “Create Workspace“ and enter the details. <br />The summary of your Workspace will be displayed here."
    }
  },
  "th": {
    "title": "ระบบจัดการเวิร์คสเปซ",
    "workspace": "เวิร์คสเปซ",
    "description": "เป็นการจัดการเวิร์คสเปซและดูรายละเอียดของเวิร์คสเปซเพื่อให้การทำงานร่วมกันเต็มประสิทธิภาพมากขึ้น สามารถสร้าง แก้ไข และลบได้ในที่เดียว",
    "add_workspace": "เพิ่มเวิร์คสเปซ",
    "workspace_table":{
      "go_to": "ไปยังเวิร์คสเปซ",
      "owner": "เจ้าของ",
      "description": "รูปแบบ",
      "web": "เว็บไซต์",
      "kanban": "คัมบัง",
      "agile": "อไจล์",
      "waterfall": "วอเตอร์ฟอลล์"
    },
    "search":"ค้นหาชื่อเวิร์คสเปซ",
    "empty_workspace":{
      "title":"ไม่พบเวิร์คสเปซ",
      "description":"สร้างเวิร์คสเปซได้ง่ายๆ โดยการกดปุ่ม “สร้างเวิร์คสเปซ“ และใส่รายละเอียดต่าง เวิร์คสเปซจะถูกแสดงภาพรวมทั้งหมดที่นี่"
    }
  }
}
</i18n>
